<template>
    <pop-view @onCloseClick="onCloseClick">
        <div class="add-ring">
            <div class="add-ring-top">
                <p class="add-ring-top-title">新增设备</p>
                <img class="add-ring-top-close" src="@imgs/close-card.png" @click="onCloseClick" />
            </div>
            <div class="add-ring-middle">
                <div class="add-ring-middle-sn">
                    <p class="add-ring-middle-sn-title">设备SN：</p>
                    <border-input class="add-ring-middle-sn-input" @onInput="onSNInput"></border-input>
                </div>
            </div>
            <div class="add-ring-bottom">
                <button class="add-ring-bottom-cancel" @click="onCancelClick">取消</button>
                <button class="add-ring-bottom-confirm" @click="onConfirmClick">
                    确定
                </button>
            </div>
        </div>
    </pop-view>
</template>

<script>
import BorderInput from "@c/common/border-input.vue";
import PopView from "@c/common/pop-view.vue";

export default {
    components: {
        PopView,
        BorderInput,
    },

    data: function () {
        return {
            sn: "",
        };
    },

    methods: {
        onCloseClick: function () {
            this.$emit("onCloseClick");
        },

        onSNInput: function (value) {
            this.sn = value;
        },

        onCancelClick: function () {
            this.onCloseClick();
        },

        onConfirmClick: function () {
            this.$emit("onConfirmClick", {
                sn: this.sn
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.add-ring {
    width: rem(430);

    &-top {
        width: 100%;
        height: rem(55);
        border-bottom: rem(1) solid rgba($color: black, $alpha: 0.09);
        box-sizing: border-box;
        padding: rem(0) rem(24);
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-title {
            color: rgba($color: black, $alpha: 0.85);
            font-size: rem(16);
        }

        &-close {
            width: rem(16);
            height: rem(16);
        }
    }

    &-middle {
        color: rgba($color: black, $alpha: 0.85);
        font-size: rem(14);
        line-height: rem(20);
        border-bottom: rem(1) solid rgba($color: black, $alpha: 0.09);
        width: 100%;
        height: rem(90);

        &-sn {
            height: rem(32);
            margin-top: rem(22);
            margin-left: rem(38);
            display: flex;
            align-items: center;

            &-input {
                width: rem(286);
                height: 100%;
            }
        }
    }

    &-bottom {
        width: 100%;
        height: rem(73);
        box-sizing: border-box;
        padding-top: rem(10);
        padding-right: rem(16);
        display: flex;
        justify-content: flex-end;

        &-confirm {
            color: white;
            background-color: #325FAC;
            font-size: rem(14);
            border-radius: rem(3);
            width: rem(65);
            height: rem(32);
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-cancel {
            color: rgba($color: #000000, $alpha: 0.65);
            background-color: white;
            font-size: rem(14);
            border-radius: rem(3);
            border: rem(1) solid #e8e8e8;
            width: rem(65);
            height: rem(32);
            margin-right: rem(8);
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
</style>