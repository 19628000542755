<template>
  <div class="item-bar">
    <div class="item-bar-unit sn">设备SN</div>
    <div class="item-bar-unit current-user">当前用户</div>
    <div class="item-bar-unit edition">固件版本</div>
    <div class="item-bar-unit organization">机构</div>
    <div v-if="isSeniorAdministratorOrOperationMember" class="item-bar-unit remark">备注</div>
    <div class="item-bar-unit operation">操作</div>
  </div>
</template>

<script>
export default {
  computed: {
    isSeniorAdministratorOrOperationMember() {
      return this.$store.getters.isSeniorAdministratorOrOperationMember
    }
  }
};
</script>

<style lang="scss" scoped>
.item-bar {
  background-color: #fafafa;
  border-bottom: rem(1) solid #e8e8e8;
  width: 100%;
  height: rem(54);
  position: relative;
  display: flex;

  &-unit {
    color: rgba($color: black, $alpha: 0.85);
    font-size: rem(14);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sn {
    flex: 15;
  }

  .current-user {
    flex: 10;
  }

  .edition {
    flex: 10;
  }

  .organization {
    flex: 25;
  }

  .remark {
    flex: 20;
  }

  .operation {
    flex: 20;
  }
}
</style>