<template>
    <list-title-bar :titleArray="titleArray"></list-title-bar>
</template>

<script>
import ListTitleBar from "@c/operation-log/common/paging-list/list-title-bar.vue";

export default {
    components: { ListTitleBar },

    computed: {
        titleArray() {
            return [
                { name: "设备SN", percent: "15%" },
                { name: "固件版本", percent: "15%" },
                { name: "收取报告方式", percent: "15%" },
                { name: "添加时间", percent: "18%" },
                { name: "更新时间", percent: "18%" },
                { name: "机构", percent: "19%" },
            ];
        },
    },
};
</script>