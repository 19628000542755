<template>
    <list-cell :isSelected="isSelected" @onSelectClick="onSelectClick">
        <div class="organization-cell">
            <cell-unit :style="{ width: '15%' }" :title="ringSN"></cell-unit>
            <cell-unit :style="{ width: '15%' }" :title="edition"></cell-unit>
            <cell-unit :style="{ width: '17%' }" :title="createTimeStr"></cell-unit>
            <cell-unit :style="{ width: '17%' }" :title="updateTimeStr"></cell-unit>
            <cell-unit :style="{ width: '26%' }" :title="organization"></cell-unit>
            <cell-unit :style="{ width: '10%', cursor: 'pointer' }" title="删除" color="#F45C50"
                :icon="require('@imgs/trash.png')" @onClick="onDeleteClick"></cell-unit>
        </div>
    </list-cell>
</template>

<script>
import CellUnit from "@c/operation-log/common/paging-list/cell-unit.vue";
import ListCell from "@c/operation-log/common/paging-list/list-cell.vue";
import CellUnitItem from "@c/operation-log/common/paging-list/cell-unit.vue";

export default {
    components: {
        ListCell,
        CellUnit,
        CellUnitItem
    },

    props: {
        index: Number,
        model: Object,
        isSelected: Boolean,
    },

    computed: {
        ringSN() {
            return placeholderText(this.model.ringSn)
        },

        edition() {
            return placeholderText(this.model.swVersion)
        },

        createTimeStr() {
            return placeholderText(this.model.gmtCreate)
        },

        updateTimeStr() {
            return placeholderText(this.model.gmtModify)
        },

        organization() {
            return placeholderText(this.model.hospitalName)
        }
    },

    methods: {
        onSelectClick() {
            this.$emit("onSelectClick", this.index);
        },

        onDeleteClick() {
            this.$alert.show("设备操作", "是否要删除设备？", () => {
                this.ringDeleteRequest();
            });
        },

        async ringDeleteRequest() {
            try {
                const params = {
                    id: this.model.id
                };
                await this.$api.deleteRing(params)
                this.$toast.showGreen("指环删除成功");
                this.$emit("onDeleteRingSucceed");
            } catch (error) {
                this.$toast.showRed(error);
            }
        },

    },
};
</script>

<style lang="scss" scoped>
.organization-cell {
    height: 100%;
    display: flex;
    align-items: center;

    &-check {
        color: #325FAC;
        font-size: rem(14);
        margin-left: rem(14);
    }

    &-operate {
        width: 12%;
        box-sizing: border-box;
        padding-right: 5px;
        display: flex;
        align-items: center;
        justify-content: space-around;

        &-seprator {
            background-color: rgb(233, 233, 233);
            height: 14px;
            width: 1px;
        }
    }
}
</style>