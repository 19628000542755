<template>
  <div class="cell" :class="{ selected: isSelected }" @click="onSelectClick">
    <div class="cell-unit sn">{{ model.sn }}</div>
    <div class="cell-unit current-user">
      <div class="cell-unit-current-user-container">
        <p class="cell-unit-current-user-container-label">
          {{ currentUserLabelText }}
        </p>
        <button class="cell-unit-current-user-container-button" @click="onButtonClick">
          {{ currentUserButtonText }}
        </button>
      </div>
    </div>
    <div class="cell-unit edition">{{ version }}</div>
    <div class="cell-unit organization">{{ model.hospitalName }}</div>
    <div v-if="isSeniorAdministratorOrOperationMember" class="cell-unit remark">{{ remark }}</div>

    <div class="cell-unit operation">
      <div class="cell-unit-operation-container">
        <cell-unit color="#f45c50" :iconSRC="trashSRC" title="删除" @onClick="onDeleteClick"></cell-unit>
        <div class="cell-unit-operation-container-slash"></div>
        <cell-unit color="#325FAC" :iconSRC="editSRC" title="编辑" @onClick="onEditClick"></cell-unit>
      </div>
    </div>
  </div>
</template>

<script>
import CellUnit from "@c/user-manage/cell-unit.vue";
export default {
  components: {
    CellUnit,
  },

  props: {
    model: Object,
    isSelected: Boolean,
  },

  data: function () {
    return {
      trashSRC: require("@imgs/trash.png"),
      previewSRC: require("@imgs/report-preview.png"),
      editSRC: require("@imgs/edit.png"),
    };
  },

  computed: {
    isSeniorAdministratorOrOperationMember() {
      return this.$store.getters.isSeniorAdministratorOrOperationMember
    },

    version: function () {
      return placeholderText(this.model.versionNo)
    },

    currentUserLabelText() {
      switch (this.model.deviceStatus) {
        case DEVICE_BINDING_STATUS_CODE.已借出:
          return this.model.userName;
        case DEVICE_BINDING_STATUS_CODE.待回收:
          return this.model.userName;
        case DEVICE_BINDING_STATUS_CODE.闲置:
          return "";
        case DEVICE_BINDING_STATUS_CODE.维修:
          return "无";
        case DEVICE_BINDING_STATUS_CODE.维保:
          return "无";
        default:
          break;
      }
    },

    currentUserButtonText() {
      switch (this.model.deviceStatus) {
        case DEVICE_BINDING_STATUS_CODE.已借出:
          return "终止";
        case DEVICE_BINDING_STATUS_CODE.待回收:
          return "";
        case DEVICE_BINDING_STATUS_CODE.闲置:
          return "+ 添加";
        case DEVICE_BINDING_STATUS_CODE.维修:
          return "";
        case DEVICE_BINDING_STATUS_CODE.维保:
          return "";
        default:
          break;
      }
    },

    remark: function () {
      return placeholderText(this.model.remark)
    },
  },

  methods: {
    onSelectClick: function () {
      this.$emit("onSelectClick", this.model);
    },

    onButtonClick: function () {
      this.onSelectClick();
      switch (this.model.deviceStatus) {
        case DEVICE_BINDING_STATUS_CODE.已借出:
          this.$emit("onStopClick");
          break;
        case DEVICE_BINDING_STATUS_CODE.闲置:
          this.$emit("onAddUserClick");
          break;
        default:
          break;
      }
    },

    onDeleteClick: function () {
      this.onSelectClick();
      this.$emit("onDeleteClick");
    },

    onEditClick: function () {
      this.onSelectClick();
      this.$emit("onEditClick");
    },
  },
};
</script>

<style lang="scss" scoped>
.cell {
  color: #666666;
  background-color: white;
  font-size: rem(14);
  word-break: break-word;
  line-height: rem(22);
  text-align: center;
  border-bottom: rem(1) solid #e8e8e8;
  width: 100%;
  min-height: rem(54);
  display: flex;
  align-items: center;

  &:hover {
    background-color: #f7f7f7;
  }

  &-unit {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &-current-user-container {
      display: flex;
      align-items: center;

      &-label {
        color: rgba($color: black, $alpha: 0.65);
        font-size: rem(14);
        margin-right: rem(5);
      }

      &-button {
        color: #325FAC;
        font-size: rem(14);
        text-decoration: underline;
      }
    }

    &-operation-container {
      display: flex;
      align-items: center;

      &-slash {
        background-color: #e9e9e9;
        width: rem(1);
        height: rem(14);
        margin-left: rem(10);
        margin-right: rem(10);
      }
    }
  }

  .sn {
    flex: 15;
  }

  .current-user {
    flex: 10;
  }

  .edition {
    flex: 10;
  }

  .organization {
    flex: 25;
  }

  .remark {
    flex: 20;
  }

  .operation {
    flex: 20;
  }
}

.selected {
  background-color: #e6f7ff;

  &:hover {
    background-color: #e6f7ff;
  }
}
</style>