<template>
    <div class="child-ring">
        <page-list-container :total="total" :pageSize="pageSize" :inputCurrentPage="currentPage"
            @onPageChange="onPageChange" @onSizeChange="onSizeChange">
            <div>
                <ring-title-bar></ring-title-bar>
                <ring-cell v-for="(item, index) of dataArray" :key="index" :model="item" :index="index"
                    :isSelected="selectedIndex === index" @onSelectClick="onCellSelectClick"></ring-cell>
            </div>
        </page-list-container>
    </div>
</template>

<script>
import PageListContainer from "@c/operation-log/common/paging-list/page-list-container.vue";
import RingTitleBar from "@c/device-manage/child-ring/child-ring-title-bar.vue";
import RingCell from "@c/device-manage/child-ring/child-ring-cell.vue";

export default {
    name: "organization",
    components: {
        PageListContainer,
        RingTitleBar,
        RingCell,
    },

    data() {
        return {
            //列表
            dataArray: [],
            total: 0,
            pageSize: undefined,
            currentPage: 1,
            selectedModel: null,
            selectedIndex: null,
            keyword: undefined,
        };
    },

    computed: {
        hospitalID() {
            return this.$store.state.selectedHospital.hospitalid;
        },
    },

    mounted(){
        this.pageSize = bestPageSizeComputer()
        this.getRingList();
    },

    watch: {
        hospitalID() {
            this.currentPage = 1
            this.getRingList()
        },
    },

    methods: {
        //cell
        onCellSelectClick(index) {
            this.selectedIndex = index;
            this.selectedModel = this.dataArray[index];
        },

        onSearchClick(value) {
            this.currentPage = 1;
            this.keyword = value;
            this.getRingList();
        },

        //分页
        onPageChange(currentPage) {
            this.currentPage = currentPage;
            this.getRingList();
        },

        onSizeChange(size) {
            this.pageSize = size
            this.currentPage = 1
            this.getRingList();
        },

        // method
        async getRingList() {
            this.selectedIndex = null;
            this.selectedModel = null;
            try {
                const params = {
                    "hospitalId": this.hospitalID,
                    "pageNum": this.currentPage,
                    "pageSize": this.pageSize,
                    "sn": this.keyword
                };
                const data = await this.$api.getChildRingList(params);
                this.dataArray = data.list;
                this.total = data.total;
                this.totalPage = data.pages;

            } catch (error) {
                this.$toast.showRed(error);
            }
        },
    },
};
</script>


<style lang="scss" scoped>
.child-ring {
    font-size: 19px;
    width: 100%;
}
</style>