<template>
  <pop-view @onCloseClick="onCloseClick">
    <div class="add-device">
      <div class="add-device-top">
        <p class="add-device-top-title">新增设备</p>
        <img
          class="add-device-top-close"
          src="@imgs/close-card.png"
          @click="onCloseClick"
        />
      </div>
      <div class="add-device-middle">
        <div class="add-device-middle-sn">
          <p class="add-device-middle-sn-title">设备SN：</p>
          <border-input
            class="add-device-middle-sn-input"
            @onInput="onSNInput"
          ></border-input>
        </div>
        <div v-if="isSeniorAdministratorOrOperationMember" class="add-device-middle-remark">
          <p class="add-device-middle-remark-title">备注：</p>
          <border-input
            class="add-device-middle-remark-input"
            @onInput="onRemarkInput"
          ></border-input>
        </div>
        <div class="add-device-middle-scene">
          <p class="add-device-middle-secen-title">使用场景：</p>
          <switch-picker
            left="院外"
            right="院内"
            @onPick="onScenePick"
          ></switch-picker>
        </div>
        <div v-show="isInner" class="add-device-middle-room">
          <p class="add-device-middle-room-title">房间号：</p>
          <border-input
            class="add-device-middle-room-input"
            @onInput="onRoomInput"
          ></border-input>
        </div>
      </div>
      <div class="add-device-bottom">
        <button class="add-device-bottom-cancel" @click="onCancelClick">取消</button>
        <button class="add-device-bottom-confirm" @click="onConfirmClick">
          确定
        </button>
      </div>
    </div>
  </pop-view>
</template>

<script>
import BorderInput from "@c/common/border-input.vue";
import SwitchPicker from "./switch-picker.vue";
import PopView from "@c/common/pop-view.vue";

export default {
  components: {
    PopView,
    BorderInput,
    SwitchPicker,
  },

  data: function () {
    return {
      switchPickerDataArray: ["院外", "院内"],
      isInner: false,
      sn: "",
      remark:"",
      room: "",
    };
  },

  computed:{
    isSeniorAdministratorOrOperationMember() {
      return this.$store.getters.isSeniorAdministratorOrOperationMember
    },
  },

  watch: {
    isInner: function (newValue) {
      if (newValue === false) {
        this.room = "";
      }
    },
  },

  methods: {
    onCloseClick: function () {
      this.$emit("onCloseClick");
    },

    onScenePick: function (isInner) {
      this.isInner = isInner;
    },

    onSNInput: function (value) {
      this.sn = value;
    },

    onRemarkInput: function (value) {
      this.remark = value;
    },

    onRoomInput: function (value) {
      this.room = value;
    },

    onCancelClick: function () {
      this.onCloseClick();
    },

    onConfirmClick: function () {
      if (this.sn.length === 0) {
        this.$toast.showRed(MSG.snEmpty);
        return;
      }
      if (this.isInner && this.room === "") {
        this.$toast.showRed(MSG.roomEmpty);
        return;
      }
      this.$emit("onConfirmClick", {
        sn: this.sn,
        remark:this.remark,
        isInner: this.isInner,
        room: this.room,
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.add-device {
  width: rem(430);

  &-top {
    width: 100%;
    height: rem(55);
    border-bottom: rem(1) solid rgba($color: black, $alpha: 0.09);
    box-sizing: border-box;
    padding: rem(0) rem(24);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-title {
      color: rgba($color: black, $alpha: 0.85);
      font-size: rem(16);
    }

    &-close {
      width: rem(16);
      height: rem(16);
    }
  }

  &-middle {
    color: rgba($color: black, $alpha: 0.85);
    font-size: rem(14);
    line-height: rem(20);
    border-bottom: rem(1) solid rgba($color: black, $alpha: 0.09);
    width: 100%;
    height: rem(300);

    &-sn {
      height: rem(32);
      margin-top: rem(22);
      margin-left: rem(38);
      display: flex;
      align-items: center;

      &-input {
        width: rem(286);
        height: 100%;
      }
    }

    &-remark {
      height: rem(32);
      margin-top: rem(33);
      margin-left: rem(38);
      display: flex;
      align-items: center;

      &-input {
        width: rem(306);
        height: 100%;
      }
    }

    &-scene {
      margin-top: rem(33);
      margin-left: rem(38);
      display: flex;
      align-items: center;
    }

    &-room {
      height: rem(32);
      margin-top: rem(31);
      margin-left: rem(38);
      display: flex;
      align-items: center;

      &-input {
        width: rem(108);
        height: 100%;
      }
    }
  }

  &-bottom {
    width: 100%;
    height: rem(73);
    box-sizing: border-box;
    padding-top: rem(10);
    padding-right: rem(16);
    display: flex;
    justify-content: flex-end;

    &-confirm {
      color: white;
      background-color: #325FAC;
      font-size: rem(14);
      border-radius: rem(3);
      width: rem(65);
      height: rem(32);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-cancel {
      color: rgba($color: #000000, $alpha: 0.65);
      background-color: white;
      font-size: rem(14);
      border-radius: rem(3);
      border: rem(1) solid #e8e8e8;
      width: rem(65);
      height: rem(32);
      margin-right: rem(8);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>