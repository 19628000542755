<template>
    <list-title-bar :titleArray="titleArray"></list-title-bar>
  </template>
  
  <script>
  import ListTitleBar from "@c/operation-log/common/paging-list/list-title-bar.vue";
  
  export default {
    components: { ListTitleBar },
  
    computed: {
      titleArray() {
        return [
          { name: "设备SN", percent: "15%" },
          { name: "固件版本", percent: "15%" },
          { name: "添加时间", percent: "17%" },
          { name: "更新时间", percent: "17%" },
          { name: "机构", percent: "26%" },
          { name: "操作", percent: "10%" },
        ];
      },
    },
  };
  </script>