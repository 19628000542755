<template>
    <list-cell :isSelected="isSelected" @onSelectClick="onSelectClick">
        <div class="organization-cell">
            <cell-unit :style="{ width: '15%' }" :title="ringSN"></cell-unit>
            <cell-unit :style="{ width: '15%' }" :title="edition"></cell-unit>
            <cell-unit :style="{ width: '15%' }" :title="receiveWay"></cell-unit>
            <cell-unit :style="{ width: '18%' }" :title="createTimeStr"></cell-unit>
            <cell-unit :style="{ width: '18%' }" :title="updateTimeStr"></cell-unit>
            <cell-unit :style="{ width: '19%' }" :title="organization"></cell-unit>
        </div>
    </list-cell>
</template>

<script>
import CellUnit from "@c/operation-log/common/paging-list/cell-unit.vue";
import ListCell from "@c/operation-log/common/paging-list/list-cell.vue";

export default {
    components: {
        ListCell,
        CellUnit
    },

    props: {
        index: Number,
        model: Object,
        isSelected: Boolean,
    },

    computed: {
        ringSN() {
            return placeholderText(this.model.sn)
        },

        edition() {
            return placeholderText(this.model.versionNumber)
        },

        receiveWay() {
            return placeholderText(findKey(RECEIVE_REPORT_WAY, this.model.reportCollectionMethod))
        },

        createTimeStr() {
            return placeholderText(this.model.gmtCreate)
        },

        updateTimeStr() {
            return placeholderText(this.model.gmtModify)
        },

        organization() {
            return placeholderText(this.model.hospitalName)
        }
    },

    methods: {
        onSelectClick() {
            this.$emit("onSelectClick", this.index);
        }

    },
};
</script>

<style lang="scss" scoped>
.organization-cell {
    height: 100%;
    display: flex;
    align-items: center;

    &-check {
        color: #325FAC;
        font-size: rem(14);
        margin-left: rem(14);
    }

    &-operate {
        width: 12%;
        box-sizing: border-box;
        padding-right: 5px;
        display: flex;
        align-items: center;
        justify-content: space-around;

        &-seprator {
            background-color: rgb(233, 233, 233);
            height: 14px;
            width: 1px;
        }
    }
}
</style>