<template>
  <pop-view @onCloseClick="onCloseClick">
    <div class="edit-device">
      <div class="edit-device-top">
        <p class="edit-device-top-title">编辑设备</p>
        <button class="edit-device-top-close" @click="onCloseClick" :style="{
          backgroundImage: `url(${require('@imgs/close-card.png')})`,
        }"></button>
      </div>
      <div class="edit-device-middle">
        <div class="edit-device-middle-sn">
          <p class="edit-device-middle-sn-title">设备SN：</p>
          <p class="add-device-middle-sn-sn">{{ sn }}</p>
        </div>
        <div v-if="isSeniorAdministratorOrOperationMember" class="edit-device-middle-remark">
          <p class="edit-device-middle-remark-title">备注：</p>
          <border-input class="edit-device-middle-remark-input" :initalValue="remark" @onInput="onRemarkInput"></border-input>
        </div>
        <div class="edit-device-middle-scene">
          <p class="edit-device-middle-secen-title">使用场景：</p>
          <switch-picker left="院外" right="院内" :initialIsPickRight="isInner" @onPick="onScenePick"></switch-picker>
        </div>
        <div v-show="isInner" class="edit-device-middle-room">
          <p class="edit-device-middle-room-title">房间号：</p>
          <border-input class="edit-device-middle-room-input" :initalValue="room" @onInput="onRoomInput"></border-input>
        </div>
        <!-- <div class="edit-device-middle-status">
          <div class="edit-device-middle-status-top">
            <p class="edit-device-middle-status-top-title">设备状态：</p>
            <button
              class="edit-device-middle-status-top-status"
              @click="onStatusClick"
            >
              {{ deviceStatus }}
            </button>
          </div>
          <pick-list
            class="edit-device-middle-status-pick-list"
            :isShow="isShowPickList"
            :pickArray="deviceStatusArray"
            :selectedIndex="selectedIndex"
            @onListItemClick="onListItemClick"
          ></pick-list>
        </div> -->
      </div>
      <div class="edit-device-bottom">
        <button class="edit-device-bottom-cancel" @click="onCancelClick">
          取消
        </button>
        <button class="edit-device-bottom-confirm" @click="onConfirmClick">
          确定
        </button>
      </div>
    </div>
  </pop-view>
</template>

<script>
import PopView from "@c/common/pop-view.vue";
import BorderInput from "@c/common/border-input.vue";
import SwitchPicker from "@c/device-manage/monitor/device-add/switch-picker.vue";
import PickList from "@c/common/pick-list.vue";

export default {
  components: {
    PopView,
    BorderInput,
    SwitchPicker,
    PickList,
  },

  props: {
    model: Object,
  },

  data: function () {
    return {
      switchPickerDataArray: ["院外", "院内"],
      isInner: false,
      sn: "",
      remark: "",
      room: "",
      //pick-list
      isShowPickList: false,
      selectedIndex: undefined,
    };
  },

  computed: {
    isSeniorAdministratorOrOperationMember() {
      return this.$store.getters.isSeniorAdministratorOrOperationMember
    },

    deviceStatusArray: function () {
      const deviceStatusArray = getObjectKeys(DEVICE_BINDING_STATUS_CODE);
      const tempArray = deviceStatusArray.slice(0, 3);
      return tempArray;
    },

    deviceStatusCodeArray: function () {
      const deviceStatusCodeArray = getObjectValues(DEVICE_BINDING_STATUS_CODE);
      const tempArray = deviceStatusCodeArray.slice(0, 3);
      return tempArray;
    },

    deviceStatus: function () {
      return this.deviceStatusArray[this.selectedIndex];
    },
  },

  watch: {
    isInner: function (newValue) {
      if (newValue === false) {
        this.room = "";
      }
    },
  },

  created: function () {
    this.isInner = this.model.scene == SCENE_CODE.院内;
    this.sn = this.model.sn;
    this.remark = this.model.remark;
    this.room = this.model.roomNo;
    this.selectedIndex = this.deviceStatusArray.findIndex(
      (item) => item === this.model.userStatusDesc
    );
  },

  methods: {
    onCloseClick: function () {
      this.$emit("onCloseClick");
    },

    onRemarkInput: function (value) {
      this.remark = value;
    },

    onStatusClick: function () {
      this.isShowPickList = !this.isShowPickList;
    },

    onListItemClick: function (selectedIndex) {
      this.isShowPickList = false;
      this.selectedIndex = selectedIndex;
    },

    onScenePick: function (isInner) {
      this.isInner = isInner;
    },

    onRoomInput: function (value) {
      this.room = value;
    },

    onCancelClick: function () {
      this.onCloseClick();
    },

    onConfirmClick: function () {
      if (this.isInner && !this.room) {
        this.$toast.showRed(MSG.roomEmpty);
        return;
      }
      console.log(this.remark)
      this.$emit("onConfirmClick", {
        isInner: this.isInner,
        remark: this.remark,
        room: this.room,
        // status: 1,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-device {
  width: rem(430);

  &-top {
    width: 100%;
    height: rem(55);
    border-bottom: rem(1) solid rgba($color: black, $alpha: 0.09);
    box-sizing: border-box;
    padding: rem(0) rem(24);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-title {
      color: rgba($color: black, $alpha: 0.85);
      font-size: rem(16);
    }

    &-close {
      background-size: rem(16) rem(16);
      background-repeat: no-repeat;
      width: rem(16);
      height: rem(16);
    }
  }

  &-middle {
    color: rgba($color: black, $alpha: 0.85);
    font-size: rem(14);
    line-height: rem(20);
    border-bottom: rem(1) solid rgba($color: black, $alpha: 0.09);
    width: 100%;
    height: rem(300);

    &-sn {
      color: black;
      height: rem(32);
      margin-top: rem(28);
      margin-left: rem(47);
      display: flex;
      align-items: center;
    }

    &-remark {
      height: rem(32);
      margin-top: rem(31);
      margin-left: rem(66);
      display: flex;
      align-items: center;

      &-input {
        width: rem(250);
        height: 100%;
      }
    }

    &-status {
      margin-top: rem(28);
      margin-left: rem(38);
      position: relative;

      &-top {
        height: rem(32);
        display: flex;
        align-items: center;

        &-status {
          color: black;
          border: rem(1) solid rgba($color: black, $alpha: 0.15);
          border-radius: rem(4);
          width: rem(108);
          height: rem(32);
          box-sizing: border-box;
          padding-left: rem(12);
          display: flex;
          align-items: center;
        }
      }

      &-pick-list {
        position: absolute;
        top: rem(32);
        left: rem(70);
        width: rem(108);
      }
    }

    &-scene {
      margin-top: rem(33);
      margin-left: rem(37);
      display: flex;
      align-items: center;
    }

    &-room {
      height: rem(32);
      margin-top: rem(31);
      margin-left: rem(51);
      display: flex;
      align-items: center;

      &-input {
        width: rem(108);
        height: 100%;
      }
    }
  }

  &-bottom {
    width: 100%;
    height: rem(73);
    box-sizing: border-box;
    padding-top: rem(10);
    padding-right: rem(16);
    display: flex;
    justify-content: flex-end;

    &-confirm {
      color: white;
      background-color: #325FAC;
      font-size: rem(14);
      border-radius: rem(3);
      width: rem(65);
      height: rem(32);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-cancel {
      color: rgba($color: #000000, $alpha: 0.65);
      background-color: white;
      font-size: rem(14);
      border-radius: rem(3);
      border: rem(1) solid #e8e8e8;
      width: rem(65);
      height: rem(32);
      margin-right: rem(8);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>